import { Photograph } from './Photograph'
import { User } from './User'
import { Activity } from './Activity'
import { Location } from './Location'
import { QuantityDiscount } from './QuantityDiscount'
import { isNil } from 'lodash'
import { Event } from './Event'
import AlbumInterface from 'services/Interfaces/Album/Album.interface'

export default class Album {
  static init(album: AlbumInterface): Album {
    const newAlbum = new Album()
    if (isNil(album)) {
      return newAlbum
    }
    newAlbum.id = album.id
    newAlbum.description = album.description
    newAlbum.takenDate = album.takenDate
    newAlbum.photographs = album.photographs
    newAlbum.coverPhotographSrc = album.coverPhotographSrc
    newAlbum.owner = album.owner
    newAlbum.ownerId = album.ownerId
    newAlbum.location = album.location
    newAlbum.locationId = album.location.id
    newAlbum.activity = album.activity
    newAlbum.activityId = album.activity.id
    newAlbum.isSuspended = album.isSuspended
    newAlbum.isFree = album.isFree
    newAlbum.createdAt = album.createdAt
    newAlbum.updatedAt = album.updatedAt
    newAlbum.deletedAt = album.deletedAt
    newAlbum.starsAverage = album.starsAverage

    if (!isNil(album.currency)) {
      newAlbum.currency = album.currency
    }
    if (!isNil(album.defaultImagePrice)) {
      newAlbum.defaultImagePrice = album.defaultImagePrice
    }
    if (!isNil(album.defaultPackagePrice)) {
      newAlbum.defaultPackagePrice = album.defaultPackagePrice
    }
    if (!isNil(album.burstPrice)) {
      newAlbum.burstPrice = album.burstPrice
    }
    if (!isNil(album.event)) {
      newAlbum.event = Event.init(album.event)
      newAlbum.eventId = album.event.id
    }
    if (!isNil(album.quantityDiscount)) {
      newAlbum.quantityDiscount = QuantityDiscount.init(album.quantityDiscount)
      newAlbum.quantityDiscountId = album.quantityDiscountId
    }

    return newAlbum
  }

  id: string

  description: string

  takenDate: Date

  currency?: string | null

  commission?: number | null

  defaultImagePrice?: number | null

  defaultPackagePrice?: number | null

  burstPrice?: number | null

  quantityDiscountId?: string | null

  quantityDiscount?: QuantityDiscount | null

  photographs: Photograph[]

  coverPhotographSrc: string

  owner: User

  ownerId: string

  location: Location

  locationId: string

  activity: Activity

  activityId: string

  isSuspended: boolean

  isFree: boolean

  event?: Event | null

  eventId?: string | null

  createdAt: Date

  updatedAt: Date

  deletedAt: Date

  starsAverage: number

  userLikedAlbum: boolean
}
