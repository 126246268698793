import React, { useState, useContext, useEffect } from 'react'
import { observer } from 'mobx-react'
import { useQuery } from '../../hooks/useQuery'
import StoresContext from '../../providers/storesContext'
import Spinner from '../../components/UI/Spinner/Spinner'
import { Event } from '../../models/Event'
import ResultsPager from '../../components/UI/ResultsPager'
import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler'
import { withTranslation } from 'react-i18next'
import axios from '../../services/axios'
import EventList from './components/EventList'
import EventSearchBox from './components/EventSearchBox'
import EventFormModal from './components/EventFormModal/EventFormModal'
import EventStore from './EventsStore'
import UploadPhotographModal from 'components/UI/ImageUploader/UploadPhotographModal'
import { isNil } from 'lodash'
import SuspendEventModal from './components/SuspendEventModal/SuspendEventModal'

const Events = () => {
  const query = useQuery()
  const { authStore } = useContext(StoresContext)!
  const [eventStore] = useState(() => new EventStore(authStore))
  const [search, setSearch] = useState<string | undefined>()
  const [openEventFormModal, setOpenEventFormModal] = useState(false)
  const [eventToEdit, setEventToEdit] = useState<Event | null>(null)
  const [eventToSuspend, setEventToSuspend] = useState<Event | null>(null)
  const [openEventCoverModal, setOpenEventCoverModal] = useState<Event | null>(null)
  const [openEventBannerModal, setOpenEventBannerModal] = useState<Event | null>(null)
  const [openSuspendEventModal, setOpenSuspendEventModal] = useState<boolean>(false)

  const page = Number(query.get('page')) || 1

  const DEFAULT_PAGE_SIZE = 25

  const hideAddEventModal = (event?: Event) => {
    setOpenEventFormModal(false)
    if (event) {
      setOpenEventCoverModal(event)
    } else {
      // avoids showing undefined name while modal is fading out
      setTimeout(() => setEventToEdit(null), 300)
    }
  }

  //Suspend event
  const handleClickSuspendEvent = (event: Event) => {
    setEventToSuspend(event)
    setOpenSuspendEventModal(true)
  }
  const handleCloseSuspendEventModal = () => {
    setEventToSuspend(null)
    setOpenSuspendEventModal(false)
  }
  const handleSuccessSuspendEvent = () => {
    eventStore.suspendEvent(eventToSuspend!).then(() => {
      handleCloseSuspendEventModal()
      eventStore.searchAdminEvents(page, DEFAULT_PAGE_SIZE, search)
    })
  }

  //Edit event
  const handleClickEdit = (event: Event) => {
    setEventToEdit(event)
    setOpenEventFormModal(true)
  }

  // Add cover
  const closeAddCoverModal = (event?: Event) => {
    setOpenEventCoverModal(null)
    if (event) {
      setOpenEventBannerModal(event)
    } else {
      // avoids showing undefined name while modal is fading out
      setTimeout(() => setEventToEdit(null), 300)
    }
  }

  // Add banner
  const closeAddBannerModal = () => {
    setOpenEventBannerModal(null)
    eventStore.searchAdminEvents(1, DEFAULT_PAGE_SIZE, search)
  }

  // Search
  const handleSearch = (textToSearch: string | undefined) => {
    setSearch(textToSearch)
  }

  useEffect(() => {
    eventStore.searchAdminEvents(page, DEFAULT_PAGE_SIZE, search)
  }, [page, search])

  if (eventStore.isLoading) {
    return (
      <div className="container flex justify-center h-screen w-screen">
        <div className="mt-32 mr-32 h-16 w-16 ">
          <Spinner size={11} color="text-spotted-gold" />
        </div>
      </div>
    )
  }

  return (
    <div className="flex flex-col">
      <EventSearchBox handleSearch={handleSearch} search={search} />
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <EventList
            store={eventStore}
            handleClickEdit={handleClickEdit}
            handleClickSuspend={handleClickSuspendEvent}
            handleAddEvent={() => setOpenEventFormModal(true)}
          />
          {eventStore.events?.length > 0 && !eventStore.isLoading && (
            <ResultsPager paginator={eventStore.paginator} query={query} />
          )}
        </div>
      </div>
      {openEventFormModal && (
        <EventFormModal
          eventStore={eventStore}
          eventToEdit={eventToEdit}
          handleClose={hideAddEventModal}
          open={openEventFormModal}
        />
      )}
      {!isNil(openEventCoverModal) && (
        <UploadPhotographModal
          open={!isNil(openEventCoverModal)}
          handleClose={closeAddCoverModal}
          event={openEventCoverModal}
          isEventCover={true}
        />
      )}
      {!isNil(openEventBannerModal) && (
        <UploadPhotographModal
          open={!isNil(openEventBannerModal)}
          handleClose={closeAddBannerModal}
          event={openEventBannerModal}
          isEventBanner={true}
        />
      )}
      {openSuspendEventModal && !isNil(eventToSuspend) && (
        <SuspendEventModal
          event={eventToSuspend}
          handleClose={handleCloseSuspendEventModal}
          opened={openSuspendEventModal && !isNil(eventToSuspend)}
          handleSuspend={handleSuccessSuspendEvent}
          isLoading={eventStore.isLoading}
        />
      )}
    </div>
  )
}

export default withErrorHandler(withTranslation('common')(observer(Events)), axios)
