import axios, { adminApiInstance } from './axios'
import * as paths from './Paths'
import { AxiosResponse } from 'axios'
import AdminUsersEditRequest from './Interfaces/AdminUsers/AdminUsersEditRequest'
import AdminUsersCreateRequest from './Interfaces/AdminUsers/AdminUsersCreateRequest'
import { parseAxiosErrorResponse } from '../utility'
import { User } from '../models/User'

type AddRefereedUserInputDto = {
  referentAlias: string
  referredAlias: string
}

class UserService {
  fetchUsers(token: string, page: number, take: number, searchInput?: string) {
    const path = searchInput
      ? `${paths.USERS}?page=${page}&take=${take}&searchInput=${searchInput}`
      : `${paths.USERS}?page=${page}`
    return adminApiInstance
      .get(path, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }) => {
        return {
          users: data.data,
          paginator: data.meta,
        }
      })
      .catch(parseAxiosErrorResponse)
  }

  fetchReferentUsers(token: string) {
    const path = paths.fetchReferentUsers()
    return adminApiInstance
      .get(path, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response: AxiosResponse) => response.data)
      .catch(parseAxiosErrorResponse)
  }

  fetchRefereedUsers(token: string, page: number, take: number, userId?: string) {
    const path = `${paths.fetchRefereedUsers(userId)}?page=${page}&take=${take}`
    return adminApiInstance
      .get(path, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }) => {
        return {
          users: data.data,
          paginator: data.meta,
        }
      })
      .catch(parseAxiosErrorResponse)
  }

  fetchPhotographersUsers(token: string) {
    return axios
      .get(paths.PHOTOGRAPHERS_USERS, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }: AxiosResponse) => data)
      .catch(parseAxiosErrorResponse)
  }

  saveUser(token: string, userData: User) {
    const payload: AdminUsersCreateRequest = {
      firstName: userData.firstName,
      lastName: userData.lastName,
      email: userData.email,
      password: userData.password,
      isPhotographer: userData.isPhotographer,
      isAthlete: userData.isAthlete,
      isAdmin: userData.isAdmin,
    }
    return axios
      .post(paths.USERS, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response: AxiosResponse) => response.data)
      .catch(parseAxiosErrorResponse)
  }

  editUser(token: string, userData: User) {
    const payload: AdminUsersEditRequest = {
      firstName: userData.firstName,
      lastName: userData.lastName,
      email: userData.email,
      password: userData.password,
      roles: userData.roles.map((role) => role.type),
    }
    return adminApiInstance
      .put(paths.updateUserById(userData.id), payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response: AxiosResponse) => response.data)
      .catch(parseAxiosErrorResponse)
  }

  addRefereedUser(token: string, input: AddRefereedUserInputDto): Promise<void> {
    return adminApiInstance
      .put(paths.addRefereedUserId(), input, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response: AxiosResponse) => response.data)
      .catch(parseAxiosErrorResponse)
  }

  suspendUser(token: string, userId: string) {
    return axios
      .post(paths.suspendUserById(userId), userId, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response: AxiosResponse) => response.data)
      .catch(parseAxiosErrorResponse)
  }

  activateUser(token: string, userId: string) {
    return axios
      .post(paths.activateUserById(userId), userId, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response: AxiosResponse) => response.data)
      .catch(parseAxiosErrorResponse)
  }

  deleteUser(token: string, userId: string) {
    return axios
      .delete(paths.deleteUserById(userId), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response: AxiosResponse) => response.data)
      .catch(parseAxiosErrorResponse)
  }
}

export default UserService
