import React, { useContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { useTranslation, withTranslation } from 'react-i18next'
import StoresContext from '../../../../providers/storesContext'
import Modal, { ModalButtonsDisplay, ModalSize } from '../../../../components/UI/Modal/Modal'
import ServerError from '../../../../components/UI/ServerError'
import Spinner from '../../../../components/UI/Spinner/Spinner'
import { isNil } from 'lodash'
import { Activity } from 'models/Activity'
import { Location } from 'models/Location'
import { CurrencyEnum } from 'models/CurrencyEnum'
import { Autocomplete, Checkbox, TextField } from '@mui/material'
import UploadCoverPhotograph from 'components/UI/ImageUploader/UploadCoverPhotograph'
import { useFormik } from 'formik'
import moment from 'moment'
import Album from 'models/Album'
import CreateOrEditAlbumRequestInterface from 'services/Interfaces/Album/CreateOrEditAlbumRequest.interface'
import AlbumStore from 'containers/Albums/AlbumStore'
import getAlbumValidationSchema from './AlbumValidationSchema'
import { Event } from 'models/Event'
import ActivityStore from 'containers/Activities/ActivityStore'
import LocationStore from 'containers/Locations/LocationStore'
import EventStore from 'containers/Events/EventsStore'
import Button from 'components/UI/Button'

type AlbumFormModalType = {
  open: boolean
  handleClose: (album?: Album) => void
  albumToEdit?: Album | null
  albumStore: AlbumStore
}

const AlbumFormModal = ({
  open,
  handleClose,
  albumToEdit = null,
  albumStore,
}: AlbumFormModalType) => {
  const { t } = useTranslation()
  const { authStore } = useContext(StoresContext)!
  const [activityStore] = useState(() => new ActivityStore(authStore))
  const [locationStore] = useState(() => new LocationStore(authStore))
  const [eventStore] = useState(() => new EventStore(authStore))
  const [currencies, setCurrencies] = useState<CurrencyEnum[]>([])
  const [activities, setActivities] = useState<Activity[]>([])
  const [locations, setLocations] = useState<Location[]>([])
  const [events, setEvents] = useState<Event[]>([])
  const [customConditions, setCustomConditions] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      const [activitiesResult, locationsResult, eventsResult] = await Promise.all([
        activityStore.fetchAllAdminActivities(),
        locationStore.fetchAllAdminLocations(),
        eventStore.searchAdminEvents(1, 25),
      ])

      if (activitiesResult) setActivities(activitiesResult)
      if (locationsResult) setLocations(locationsResult)
      if (eventsResult) setEvents(eventsResult.events)

      loadCurrencies()
    }

    fetchData()
  }, [])

  const loadCurrencies = async () => {
    const currenciesList = Object.values(CurrencyEnum)
    setCurrencies(currenciesList)
  }

  const initialValues: CreateOrEditAlbumRequestInterface = !isNil(albumToEdit)
    ? {
        id: albumToEdit.id || '',
        description: albumToEdit?.description || '',
        activityId: albumToEdit?.activity?.id || '',
        locationId: albumToEdit?.location?.id || '',
        takenDate: albumToEdit?.takenDate || new Date(new Date().setHours(0, 0, 0, 0)),
        currency: albumToEdit?.currency || '',
        defaultImagePrice: albumToEdit?.defaultImagePrice || 0,
        defaultPackagePrice: albumToEdit?.defaultPackagePrice || undefined,
        burstPrice: albumToEdit?.burstPrice || undefined,
        quantityDiscountId: albumToEdit?.quantityDiscountId || null,
        isFree: albumToEdit?.isFree || false,
        eventId: albumToEdit?.eventId || null,
      }
    : {
        description: '',
        activityId: '',
        locationId: '',
        takenDate: new Date(new Date().setHours(0, 0, 0, 0)),
        currency: undefined,
        defaultImagePrice: 0,
        defaultPackagePrice: null,
        burstPrice: null,
        quantityDiscountId: null,
        isFree: false,
        eventId: null,
      }

  const formik = useFormik({
    initialValues,
    validationSchema: getAlbumValidationSchema(),
    onSubmit: (values) => {
      const submittedValues = {
        ...values,
      }

      if (isNil(albumToEdit)) {
        if (typeof values.defaultPackagePrice === 'string') {
          values.defaultPackagePrice = undefined
        }
        if (typeof values.burstPrice === 'string') {
          values.burstPrice = undefined
        }
        albumStore.createAlbum(submittedValues).then((newAlbum) => {
          if (newAlbum) {
            handleClose(newAlbum)
            albumStore.fetchAlbums(1, 25)
          }
        })
      } else {
        albumStore.editAlbum(submittedValues).then(() => {
          handleClose(albumToEdit)
          albumStore.fetchAlbums(1, 25)
        })
      }
    },
  })

  const activityOptions = activities
    .map((activity) => {
      return { value: activity.id, label: t(activity.name).toUpperCase() }
    })
    .sort((a, b) => -b.label[0].localeCompare(a.label[0]))

  const locationOptions = locations
    .map((location) => {
      return { value: location.id, label: location.spotName.toUpperCase() }
    })
    .sort((a, b) => -b.label[0].localeCompare(a.label[0]))

  const eventOptions = [
    { value: null, label: t('No event').toUpperCase() },
    ...events
      .map((event) => {
        return { value: event.id, label: event.name.toUpperCase() }
      })
      .sort((a, b) => a.label.localeCompare(b.label)),
  ]

  const event = events.find((event) => event.id === formik.values.eventId)
  useEffect(() => {
    if (!isNil(formik.values.eventId)) {
      setCustomConditions(true)
      if (!isNil(event)) {
        formik.setValues({
          ...formik.values,
          activityId: event?.activityId ?? formik.values.activityId,
          locationId: event?.locationId ?? formik.values.locationId,
          defaultImagePrice: event?.defaultImagePrice ?? formik.values.defaultImagePrice,
          defaultPackagePrice: event.defaultPackagePrice ?? formik.values.defaultPackagePrice,
          burstPrice: event.burstPrice ?? formik.values.burstPrice,
          currency: event?.currency ?? formik.values.currency,
          quantityDiscountId: event?.quantityDiscountId ?? formik.values.quantityDiscountId,
          isFree: event.isFree ?? formik.values.isFree,
        })
      }
    }
  }, [formik.values.eventId])

  if (albumStore.isLoading) {
    return (
      <div className="container flex justify-center">
        <div className="mt-14">
          <Spinner size={11} color="text-spotted-gold" />
        </div>
      </div>
    )
  }

  return (
    <Modal
      okMessage={isNil(albumToEdit) ? t('Add') : t('Save')}
      loadingOk={albumStore.isLoading}
      onCancel={handleClose}
      onSuccess={formik.handleSubmit}
      opened={open}
      buttonsDisplay={ModalButtonsDisplay.ONLY_OK_BUTTON}
      modalSize={ModalSize.MEDIUM}
    >
      <div className="w-full flex flex-col gap-3">
        <div className="text-xl text-gray-800 font-bold text-start capitalize">
          {isNil(albumToEdit) ? t('New Album') : t('Edit Album')}
        </div>
        {albumStore.error && <ServerError message={t(albumStore.error?.message)} />}
        <div className="w-full flex flex-col md:flex-row items-center gap-2">
          <div className={`w-full ${!isNil(albumToEdit) ? 'md:w-2/3' : 'md:w-1/2'}`}>
            <TextField
              fullWidth
              variant="outlined"
              id="description"
              name="description"
              label={t('Description')}
              required
              value={formik.values.description}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.description && Boolean(formik.errors.description)}
              helperText={formik.touched.description && formik.errors.description}
            />
          </div>
        </div>
        <div className="w-full flex flex-col md:flex-row md:mb-4 gap-3 items-top">
          {!isNil(albumToEdit) && (
            <div className="w-1/2">
              <p className="text-gray-400 font-bold text-sm mb-1">{t('Cover photograph')}</p>
              <UploadCoverPhotograph album={albumToEdit} />
            </div>
          )}
          <div
            className={`w-full flex flex-col justify-between gap-3 ${
              !isNil(albumToEdit) && 'md:w-2/3'
            }`}
          >
            <div className={`w-full md:flex gap-3 ${!isNil(albumToEdit) && 'md:flex-col'}`}>
              <div className={`w-full mb-3 ${isNil(albumToEdit) ? 'md:w-1/2' : 'md:mt-6'}`}>
                <TextField
                  fullWidth
                  id="takenDate"
                  name="takenDate"
                  label={t('Taken Date')}
                  required
                  InputLabelProps={{ shrink: true }}
                  type="Date"
                  value={moment.utc(formik.values.takenDate).format('YYYY-MM-DD')}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.takenDate && Boolean(formik.errors.takenDate)}
                  helperText={
                    formik.touched.takenDate
                      ? typeof formik.errors.takenDate === 'string'
                        ? formik.errors.takenDate
                        : ''
                      : ''
                  }
                />
              </div>
            </div>
            <div className={`w-full md:flex gap-3 ${!isNil(albumToEdit) && 'md:flex-col'}`}>
              <div className={`w-full mb-3 ${isNil(albumToEdit) ? 'md:w-1/2' : 'md:mb-3'}`}>
                <Autocomplete
                  options={eventOptions}
                  value={
                    eventOptions.find((option) => option.value === formik.values.eventId) || null
                  }
                  getOptionLabel={(option) => option.label}
                  onChange={(event, newValue) => {
                    formik.setFieldValue('eventId', newValue ? newValue.value : null)
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      variant="outlined"
                      name="eventId"
                      id="eventId"
                      label={t('Event')}
                      error={formik.touched.eventId && Boolean(formik.errors.eventId)}
                      helperText={formik.touched.eventId && formik.errors.eventId}
                    />
                  )}
                />
              </div>
              <div
                className={`w-full mb-3 ${
                  isNil(albumToEdit)
                    ? 'md:w-1/2'
                    : customConditions
                    ? 'flex justify-between items-center'
                    : 'md:mb-3'
                }`}
              >
                <div className={`${customConditions ? 'w-1/2' : ''}`}>
                  <Autocomplete
                    options={activityOptions}
                    value={
                      activityOptions.find(
                        (activity) => activity.value === formik.values.activityId
                      ) || null
                    }
                    getOptionLabel={(option) => option.label}
                    onChange={(activity, newValue) => {
                      formik.setFieldValue('activityId', newValue ? newValue.value : '')
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        variant="outlined"
                        name="activityId"
                        id="activityId"
                        label={t('Activity')}
                        required
                        error={formik.touched.activityId && Boolean(formik.errors.activityId)}
                        helperText={formik.touched.activityId && formik.errors.activityId}
                      />
                    )}
                  />
                </div>
                {formik.values.eventId !== initialValues.eventId &&
                formik.values.activityId === initialValues.activityId &&
                !isNil(formik.values.eventId) &&
                !isNil(event) &&
                !isNil(event.activityId) &&
                initialValues.activityId !== event.activityId ? (
                  <Button
                    goldInverted
                    extraStyle="text-spotted-gold text-xs cursor-pointer"
                    onClick={() => formik.setFieldValue('activityId', event.activityId)}
                  >
                    {t('Mantain event condition')}
                  </Button>
                ) : (
                  formik.values.eventId !== initialValues.eventId &&
                  !isNil(event) &&
                  !isNil(event.activityId) &&
                  initialValues.activityId !== event.activityId && (
                    <Button
                      inverted
                      extraStyle="text-spotted-gold text-xs cursor-pointer"
                      onClick={() => formik.setFieldValue('activityId', initialValues.activityId)}
                    >
                      {t('Mantain album condition')}
                    </Button>
                  )
                )}
              </div>
              <div
                className={`w-full mb-3 ${
                  isNil(albumToEdit)
                    ? 'md:w-1/2'
                    : customConditions
                    ? 'flex justify-between items-center'
                    : 'md:mb-3'
                }`}
              >
                <div className={`${customConditions ? 'w-1/2' : ''}`}>
                  <Autocomplete
                    options={locationOptions}
                    value={
                      locationOptions.find(
                        (location) => location.value === formik.values.locationId
                      ) || null
                    }
                    getOptionLabel={(option) => option.label}
                    onChange={(location, newValue) => {
                      formik.setFieldValue('locationId', newValue ? newValue.value : '')
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        variant="outlined"
                        name="locationId"
                        id="locationId"
                        label={t('Location')}
                        required
                        error={formik.touched.locationId && Boolean(formik.errors.locationId)}
                        helperText={formik.touched.locationId && formik.errors.locationId}
                      />
                    )}
                  />
                </div>
                {formik.values.eventId !== initialValues.eventId &&
                formik.values.locationId === initialValues.locationId &&
                !isNil(formik.values.eventId) &&
                !isNil(event) &&
                !isNil(event.locationId) &&
                initialValues.locationId !== event.locationId ? (
                  <Button
                    goldInverted
                    extraStyle="text-spotted-gold text-xs cursor-pointer"
                    onClick={() => formik.setFieldValue('locationId', event.locationId)}
                  >
                    {t('Mantain event condition')}
                  </Button>
                ) : (
                  formik.values.eventId !== initialValues.eventId &&
                  !isNil(event) &&
                  !isNil(event.locationId) &&
                  initialValues.locationId !== event.locationId && (
                    <Button
                      inverted
                      extraStyle="text-spotted-gold text-xs cursor-pointer"
                      onClick={() => formik.setFieldValue('locationId', initialValues.locationId)}
                    >
                      {t('Mantain album condition')}
                    </Button>
                  )
                )}
              </div>
              <div className="flex items-center gap-1 mt-1">
                <p className="text-sm font-bold text-gray-400">{t('Free Album')}</p>
                <Checkbox
                  onChange={() => formik.setFieldValue('isFree', !formik.values.isFree)}
                  checked={formik.values.isFree}
                />
              </div>
            </div>
          </div>
        </div>
        {!formik.values.isFree && (
          <div className="w-full flex flex-col md:mb-4 gap-3 items-top">
            <div
              className={`w-full md:w-1/2 ${
                customConditions && 'flex flex-row justify-between items-center'
              }`}
            >
              <div className={`${customConditions ? 'w-1/2' : ''}`}>
                <Autocomplete
                  options={currencies}
                  value={currencies.find((currency) => currency === formik.values.currency) || null}
                  onChange={(event, newValue) => {
                    formik.setFieldValue('currency', newValue ? newValue : undefined)
                  }}
                  getOptionLabel={(option) => (option ? option.toUpperCase() : '')}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      name="currency"
                      id="currency"
                      label={t('Currency')}
                      required
                      error={formik.touched.currency && Boolean(formik.errors.currency)}
                      helperText={formik.touched.currency && formik.errors.currency}
                    />
                  )}
                />
              </div>

              {formik.values.eventId !== initialValues.eventId &&
              formik.values.currency === initialValues.currency &&
              !isNil(formik.values.eventId) &&
              !isNil(event) &&
              !isNil(event.currency) &&
              initialValues.currency !== event.currency ? (
                <Button
                  goldInverted
                  extraStyle="text-spotted-gold text-xs cursor-pointer"
                  onClick={() => formik.setFieldValue('currency', event.currency)}
                >
                  {t('Mantain event condition')}
                </Button>
              ) : (
                formik.values.eventId !== initialValues.eventId &&
                !isNil(event) &&
                !isNil(event.currency) &&
                initialValues.currency !== event.currency && (
                  <Button
                    inverted
                    extraStyle="text-spotted-gold text-xs cursor-pointer"
                    onClick={() => formik.setFieldValue('currency', initialValues.currency)}
                  >
                    {t('Mantain album condition')}
                  </Button>
                )
              )}
            </div>
            <div
              className={`w-full md:w-1/2 ${
                customConditions && 'flex flex-row justify-between items-center'
              }`}
            >
              <div className={`${customConditions ? 'w-1/2' : ''}`}>
                <TextField
                  id="defaultImagePrice"
                  name="defaultImagePrice"
                  label={t('Default Image Price')}
                  fullWidth
                  required
                  type="number"
                  value={formik.values.defaultImagePrice}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.defaultImagePrice && Boolean(formik.errors.defaultImagePrice)
                  }
                  helperText={formik.touched.defaultImagePrice && formik.errors.defaultImagePrice}
                />
              </div>
              {formik.values.eventId !== initialValues.eventId &&
              formik.values.defaultImagePrice === initialValues.defaultImagePrice &&
              !isNil(formik.values.defaultImagePrice) &&
              !isNil(event) &&
              !isNil(event.defaultImagePrice) &&
              initialValues.defaultImagePrice !== event.defaultImagePrice ? (
                <Button
                  goldInverted
                  extraStyle="text-spotted-gold text-xs cursor-pointer"
                  onClick={() => formik.setFieldValue('defaultImagePrice', event.defaultImagePrice)}
                >
                  {t('Mantain event condition')}
                </Button>
              ) : (
                formik.values.eventId !== initialValues.eventId &&
                !isNil(event) &&
                !isNil(event.defaultImagePrice) &&
                initialValues.defaultImagePrice !== event.defaultImagePrice && (
                  <Button
                    inverted
                    extraStyle="text-spotted-gold text-xs cursor-pointer"
                    onClick={() =>
                      formik.setFieldValue('defaultImagePrice', initialValues.defaultImagePrice)
                    }
                  >
                    {t('Mantain album condition')}
                  </Button>
                )
              )}
            </div>
            <div
              className={`w-full md:w-1/2 ${
                customConditions && 'flex flex-row justify-between items-center'
              }`}
            >
              <div className={`${customConditions ? 'w-1/2' : ''}`}>
                <TextField
                  id="defaultPackagePrice"
                  name="defaultPackagePrice"
                  label={t('Default Package Price')}
                  type="number"
                  fullWidth
                  value={formik.values.defaultPackagePrice}
                  onChange={(e) => {
                    const value = e.target.value
                    formik.setFieldValue('defaultPackagePrice', value === '' ? null : Number(value))
                  }}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.defaultPackagePrice && Boolean(formik.errors.defaultPackagePrice)
                  }
                  helperText={
                    formik.touched.defaultPackagePrice && formik.errors.defaultPackagePrice
                  }
                />
              </div>
              {formik.values.eventId !== initialValues.eventId &&
              formik.values.defaultPackagePrice === initialValues.defaultPackagePrice &&
              !isNil(formik.values.defaultPackagePrice) &&
              !isNil(event) &&
              !isNil(event.defaultPackagePrice) &&
              initialValues.defaultPackagePrice !== event.defaultPackagePrice ? (
                <Button
                  goldInverted
                  extraStyle="text-spotted-gold text-xs cursor-pointer"
                  onClick={() =>
                    formik.setFieldValue('defaultPackagePrice', event.defaultPackagePrice)
                  }
                >
                  {t('Mantain event condition')}
                </Button>
              ) : (
                formik.values.eventId !== initialValues.eventId &&
                !isNil(event) &&
                !isNil(event.defaultPackagePrice) &&
                initialValues.defaultPackagePrice !== event.defaultPackagePrice && (
                  <Button
                    inverted
                    extraStyle="text-spotted-gold text-xs cursor-pointer"
                    onClick={() =>
                      formik.setFieldValue('defaultPackagePrice', initialValues.defaultPackagePrice)
                    }
                  >
                    {t('Mantain album condition')}
                  </Button>
                )
              )}
            </div>
            <div
              className={`w-full md:w-1/2 ${
                customConditions && 'flex flex-row justify-between items-center'
              }`}
            >
              <div className={`${customConditions ? 'w-1/2' : ''}`}>
                <TextField
                  id="burstPrice"
                  name="burstPrice"
                  label={t('Burst Price')}
                  type="number"
                  fullWidth
                  value={formik.values.burstPrice}
                  onChange={(e) => {
                    const value = e.target.value
                    formik.setFieldValue('burstPrice', value === '' ? null : Number(value))
                  }}
                  onBlur={formik.handleBlur}
                  error={formik.touched.burstPrice && Boolean(formik.errors.burstPrice)}
                  helperText={formik.touched.burstPrice && formik.errors.burstPrice}
                />
              </div>
              {formik.values.eventId !== initialValues.eventId &&
              formik.values.burstPrice === initialValues.burstPrice &&
              !isNil(formik.values.burstPrice) &&
              !isNil(event) &&
              !isNil(event.burstPrice) &&
              initialValues.burstPrice !== event.burstPrice ? (
                <Button
                  goldInverted
                  extraStyle="text-spotted-gold text-xs cursor-pointer"
                  onClick={() => formik.setFieldValue('burstPrice', event.burstPrice)}
                >
                  {t('Mantain event condition')}
                </Button>
              ) : (
                formik.values.eventId !== initialValues.eventId &&
                !isNil(event) &&
                !isNil(event.burstPrice) &&
                initialValues.burstPrice !== event.burstPrice && (
                  <Button
                    inverted
                    extraStyle="text-spotted-gold text-xs cursor-pointer"
                    onClick={() => formik.setFieldValue('burstPrice', initialValues.burstPrice)}
                  >
                    {t('Mantain album condition')}
                  </Button>
                )
              )}
            </div>
            <div
              className={`w-full md:w-1/2 ${
                customConditions && 'flex flex-row justify-between items-center gap-2'
              }`}
            >
              <div className={`${customConditions ? 'w-1/2 md:w-3/4' : ''}`}>
                <TextField
                  fullWidth
                  variant="outlined"
                  id="quantityDiscountId"
                  name="quantityDiscountId"
                  label={t('QuantityDiscountId')}
                  value={formik.values.quantityDiscountId}
                  onChange={(e) => {
                    const value = e.target.value
                    formik.setFieldValue('quantityDiscountId', value === '' ? null : value)
                  }}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.quantityDiscountId && Boolean(formik.errors.quantityDiscountId)
                  }
                  helperText={formik.touched.quantityDiscountId && formik.errors.quantityDiscountId}
                />
              </div>
              {formik.values.eventId !== initialValues.eventId &&
              formik.values.quantityDiscountId === initialValues.quantityDiscountId &&
              !isNil(formik.values.quantityDiscountId) &&
              !isNil(event) &&
              !isNil(event.quantityDiscountId) &&
              initialValues.quantityDiscountId !== event.quantityDiscountId ? (
                <Button
                  goldInverted
                  extraStyle="text-spotted-gold text-xs cursor-pointer"
                  onClick={() =>
                    formik.setFieldValue('quantityDiscountId', event.quantityDiscountId)
                  }
                >
                  {t('Mantain event condition')}
                </Button>
              ) : (
                formik.values.eventId !== initialValues.eventId &&
                !isNil(event) &&
                !isNil(event.quantityDiscountId) &&
                initialValues.quantityDiscountId !== event.quantityDiscountId && (
                  <Button
                    inverted
                    extraStyle="text-spotted-gold text-xs cursor-pointer"
                    onClick={() =>
                      formik.setFieldValue('quantityDiscountId', initialValues.quantityDiscountId)
                    }
                  >
                    {t('Mantain album condition')}
                  </Button>
                )
              )}
            </div>
          </div>
        )}
      </div>
    </Modal>
  )
}

export default withTranslation('common')(observer(AlbumFormModal))
